import React from "react";
import { Navigate } from "react-router-dom";

// GENERAL
import { RoutesHome } from "../../routes/Home"
import { RoutesScan } from "../../routes/Scan"

// SEGMENT RENTALS
import { RoutesRentalsActions } from "../../routes/Rentals/Actions"
import { RoutesRentalsActionsScan } from "../../routes/Rentals/Actions/Scan"
import { RoutesRentalsActionsRequest } from "../../routes/Rentals/Actions/Request"
import { RoutesRentalsOverview } from "../../routes/Rentals/Overview"
import { RoutesRentalsCasesActions } from "../../routes/Rentals/Cases/Actions"
import { RoutesRentalsCasesDetails } from "../../routes/Rentals/Cases/Details"
import { RoutesRentalsCasesActionsRetour } from "../../routes/Rentals/Cases/Actions/Retour"
import { RoutesRentalsCasesActionsRepair } from "../../routes/Rentals/Cases/Actions/Repair"
import { RoutesRentalsCasesInvoices } from "../../routes/Rentals/Cases/Invoices"
import { RoutesRentalsCasesDocuments } from "../../routes/Rentals/Cases/Documents"
// SEGMENT ARTICLES
import { RoutesArticlesOverview } from "../../routes/Articles/Overview"
import { RoutesArticlesActions } from "../../routes/Articles/Actions"
import { RoutesArticlesActionsScan } from "../../routes/Articles/Actions/Scan"
import { RoutesArticlesInspectionsOverview } from "../../routes/Articles/Inspections/Overview"
import { RoutesArticlesAdd } from "../../routes/Articles/Add"
import { RoutesArticlesRepairsOverview } from "../../routes/Articles/Repairs/Overview"
// Product actions
import { RoutesArticlesProductActions } from "../../routes/Articles/Product/Actions"
import { RoutesArticlesProductActionsScan } from "../../routes/Articles/Product/Actions/Scan"
import { RoutesArticlesProductActionsRelocate } from "../../routes/Articles/Product/Actions/Relocate"
import { RoutesArticlesProductActionsRepair } from "../../routes/Articles/Product/Actions/Repair"
import { RoutesArticlesProductActionsDepot } from "../../routes/Articles/Product/Actions/Depot"
import { RoutesArticlesProductActionsClient } from "../../routes/Articles/Product/Actions/Client"
import { RoutesArticlesProductActionsEdit } from "../../routes/Articles/Product/Actions/Edit"
import { RoutesArticlesProductActionsDepotout } from "../../routes/Articles/Product/Actions/Depotout"
import {RoutesArticlesProductActionsClientrepair } from "../../routes/Articles/Product/Actions/Clientrepair"
import { RoutesArticlesProductActionsReservation} from "../../routes/Articles/Product/Actions/Reservation"
// Product details
import { RoutesArticlesProductDetails } from "../../routes/Articles/Product/Details"
import { RoutesArticlesProductRepairs } from "../../routes/Articles/Product/Repairs"
import { RoutesArticlesProductInspections } from "../../routes/Articles/Product/Inspections"
import { RoutesArticlesProductCleanings } from "../../routes/Articles/Product/Cleanings"
import { RoutesArticlesProductDocuments } from "../../routes/Articles/Product/Documents"
import { RoutesArticlesProductLog } from "../../routes/Articles/Product/Log"
// Depot
import { RoutesArticlesDepotPending } from "../../routes/Articles/Depot/Pending"
import { RoutesArticlesDepotArchive } from "../../routes/Articles/Depot/Archive"
import { RoutesArticlesDepotDetails } from "../../routes/Articles/Depot/Details"
// Customer requests
import { RoutesRequestsOverview } from "../../routes/Requests/Overview"
import { RoutesRequestsDetails } from "../../routes/Requests/Details"
// Management
import { RoutesManagementInvoices } from "../../routes/Management/Invoices"
import { RoutesManagementOverviews } from "../../routes/Management/Overviews"
import { RoutesManagementOverviewsRental } from "../../routes/Management/Overviews/Rental"
import { RoutesManagementOverviewsRepairs } from "../../routes/Management/Overviews/Repairs"
import { RoutesManagementOverviewsDepot } from "../../routes/Management/Overviews/Depot"
import { RoutesManagementRentalcost } from "../../routes/Management/Rentalcost"
import { RoutesManagementOverviewsProducts } from "../../routes/Management/Overviews/Products"

export const routes = [

    // ////////// GENERAL ////////// @todo: make page not found page
    {
        path: "*",
        redirect: <Navigate to={"/home"} />,
    },
    {
        path: "/home",
        pageTitle: "Home",
        topMenu:true,
        component: <RoutesHome />,
    },
    {
        path: "/scan",
        pageTitle: "Scan QR code",
        permissionLevel:1,
        topMenu:true,
        component: <RoutesScan />,
    },

    // ////////// SEGMENT RENTALS //////////
    {
        path: "/rentals",
        pageTitle: "Verhuur",
        permissionLevel:1,
        topMenu:true,
        redirect: <Navigate to={"/rentals/active"} />,
        children: [
            {
                path: "/actions",
                pageTitle: "Acties",
                permissionLevel: 1,
                tabGroup: "rentals-overview",
                component: <RoutesRentalsActions title="Acties verhuur" parents="rentals" current="actions" />
            },
            {
                path: "/actions/scan",
                pageTitle: "Scan QR code",
                permissionLevel: 1,
                component: <RoutesRentalsActionsScan title="Scan QR code" parents="rentals/actions" current="scan" />
            },
            {
                path: "/actions/request",
                pageTitle: "Nieuwe aanvraag verhuur",
                permissionLevel: 2,
                component: <RoutesRentalsActionsRequest title="Nieuwe aanvraag verhuur" parents="rentals/actions" current="request" />
            },
            {
                path: "/active",
                pageTitle: "Verhuurdossiers",
                permissionLevel: 1,
                tabGroup: "rentals-overview",
                component: <RoutesRentalsOverview title="Actieve verhuurdossiers" parents="rentals" current="active" />,
                children: [
                    {
                        path: "/actions/:rentalCaseId",
                        pageTitle: "Acties",
                        permissionLevel: 2,
                        tabGroup: "rentals-active",
                        component: <RoutesRentalsCasesActions parents="rentals/active" current="actions" />
                    },
                    {
                        path: "/retour/:rentalCaseId",
                        pageTitle: "Retour melden",
                        permissionLevel: 2,
                        component: <RoutesRentalsCasesActionsRetour parents="rentals/active" current="retour" tabGroup={"rentals-active"} />
                    },
                    {
                        path: "/details/:rentalCaseId",
                        pageTitle: "Details",
                        permissionLevel: 1,
                        tabGroup: "rentals-active",
                        component: <RoutesRentalsCasesDetails parents="rentals/active" current="details" />
                    },
                    {
                        path: "/repair/:rentalCaseId",
                        pageTitle: "Repartie melden",
                        permissionLevel: 2,
                        component: <RoutesRentalsCasesActionsRepair parents="rentals/active" current="repair" />
                    },
                    {
                        path: "/invoices/:rentalCaseId",
                        pageTitle: "Facturen",
                        permissionLevel: 1,
                        tabGroup: "rentals-active",
                        component: <RoutesRentalsCasesInvoices parents="rentals/active" current="invoices" />
                    },
                    {
                        path: "/documents/:rentalCaseId",
                        pageTitle: "Verhuurdocumenten",
                        permissionLevel: 1,
                        tabGroup: "rentals-active",
                        component: <RoutesRentalsCasesDocuments parents="rentals/active" current="documents" />
                    }
                ]
            },
            {
                path: "/waiting",
                pageTitle: "In behandeling",
                permissionLevel: 1,
                tabGroup: "rentals-overview",
                component: <RoutesRentalsOverview title="Verhuurdossiers in behandeling" parents="rentals" current="waiting" />,
                children: [
                    {
                        path: "/details/:rentalCaseId",
                        pageTitle: "Details",
                        permissionLevel: 1,
                        tabGroup: "rentals-waiting",
                        component: <RoutesRentalsCasesDetails parents="rentals/waiting" current="details" />
                    },
                    {
                        path: "/invoices/:rentalCaseId",
                        pageTitle: "Facturen",
                        permissionLevel: 1,
                        tabGroup: "rentals-waiting",
                        component: <RoutesRentalsCasesInvoices parents="rentals/waiting" current="invoices" />
                    },
                    {
                        path: "/documents/:rentalCaseId",
                        pageTitle: "Verhuurdocumenten",
                        permissionLevel: 1,
                        tabGroup: "rentals-waiting",
                        component: <RoutesRentalsCasesDocuments parents="rentals/waiting" current="documents" />
                    }
                ]
            },
            {
                path: "/archive",
                pageTitle: "Archief",
                permissionLevel: 1,
                tabGroup: "rentals-overview",
                component: <RoutesRentalsOverview title="Archief verhuur" parents="rentals" current="archive" />,
                children: [
                    {
                        path: "/details/:rentalCaseId",
                        pageTitle: "Details",
                        permissionLevel: 1,
                        tabGroup: "rentals-archive",
                        component: <RoutesRentalsCasesDetails parents="rentals/archive" current="details" />
                    },
                    {
                        path: "/invoices/:rentalCaseId",
                        pageTitle: "Facturen",
                        permissionLevel: 1,
                        tabGroup: "rentals-archive",
                        component: <RoutesRentalsCasesInvoices parents="rentals/archive" current="invoices" />
                    },
                    {
                        path: "/documents/:rentalCaseId",
                        pageTitle: "Verhuurdocumenten",
                        permissionLevel: 1,
                        tabGroup: "rentals-archive",
                        component: <RoutesRentalsCasesDocuments parents="rentals/archive" current="documents" />
                    }
                ]
            }
        ]
    },
    // ////////// SEGMENT ARTICLES //////////
    {
        path: "/articles",
        pageTitle: "Hulpmiddelenbeheer",
        permissionLevel: 1,
        topMenu:true,
        redirect: <Navigate to={"/articles/location"} />,
        children: [
            {
                path: "/actions",
                pageTitle: "Acties",
                permissionLevel: 1,
                tabGroup: "product-overview",
                component: <RoutesArticlesActions title="Acties hulpmiddelen" parents="articles" current="actions" />
            },
            {
                path: "/actions/scan",
                pageTitle: "Scan QR code",
                permissionLevel: 1,
                component: <RoutesArticlesActionsScan title="Scan QR code" parents="articles/actions" current="scan" />
            },
            // PRODUCTS ON LOCATION
            {
                path: "/location",
                pageTitle: "Op locatie",
                permissionLevel: 1,
                tabGroup: "product-overview",
                component: <RoutesArticlesOverview title="Hulpmiddelen op locatie" parents="articles" current="location" />
            },
            {
                path: "/location/product",
                redirect: <Navigate to={"/location"} />,
                children: [
                    // Product actions
                    {
                        path: "/actions/:productId",
                        pageTitle: "Acties",
                        permissionLevel: 2,
                        tabGroup: "product-details-location",
                        component: <RoutesArticlesProductActions title={null} parents="articles/location/product" current="actions" />
                    },
                    {
                        path: "/actions/scan/:productId",
                        pageTitle: "Qr code koppelen",
                        permissionLevel: 2,
                        component: <RoutesArticlesProductActionsScan title="Qr code koppelen" parents="articles/location/product/actions" current="scan" />
                    },
                    {
                        path: "/actions/relocate/:productId",
                        pageTitle: "Locatie wijzigen",
                        permissionLevel: 2,
                        component: <RoutesArticlesProductActionsRelocate title={null} parents="articles/location/product/actions" current="relocate" />
                    },
                    {
                        path: "/actions/repair/:productId",
                        pageTitle: "Reparatieverzoek",
                        permissionLevel: 2,
                        component: <RoutesArticlesProductActionsRepair title={null} parents="articles/location/product/actions" current="repair" />
                    },
                    {
                        path: "/actions/depot/:productId",
                        pageTitle: "Aanvraag depotopslag",
                        permissionLevel: 2,
                        component: <RoutesArticlesProductActionsDepot title={null} parents="articles/location/product/actions" current="depot" />
                    },
                    {
                        path: "/actions/client/:productId",
                        pageTitle: "Cliëntgegevens",
                        permissionLevel: 2,
                        component: <RoutesArticlesProductActionsClient title={null} parents="articles/location/product/actions" current="client" />
                    },
                    {
                        path: "/actions/clientrepair/:productId",
                        pageTitle: "Archief",
                        permissionLevel: 2,
                        component: <RoutesArticlesProductActionsClientrepair title={null} parents="articles/location/product/actions" current="add" />
                    },
                    { 
                        path: "/actions/edit/:productId",
                        pageTitle: "Artikelgegevens wijzigen",
                        permissionLevel: 2,
                        component: <RoutesArticlesProductActionsEdit title={null} parents="articles/location/product/actions" current="edit" />
                    },
                    // Product details
                    {
                        path: "/details/:productId",
                        pageTitle: "Details",
                        permissionLevel: 1,
                        tabGroup: "product-details-location",
                        component: <RoutesArticlesProductDetails title={null} parents="articles/location/product" current="details" />
                    },
                    {
                        path: "/repairs/:productId",
                        pageTitle: "Reparaties",
                        tabGroup: "product-details-location",
                        permissionLevel: 1,
                        component: <RoutesArticlesProductRepairs title={null} parents="articles/location/product" current="repairs" />
                    },
                    {
                        path: "/inspections/:productId",
                        pageTitle: "Keuringen",
                        tabGroup: "product-details-location",
                        permissionLevel: 1,
                        component: <RoutesArticlesProductInspections title={null} parents="articles/location/product" current="inspections" />
                    },
                    {
                        path: "/cleanings/:productId",
                        pageTitle: "Reinging",
                        permissionLevel: 1,
                        tabGroup: "product-details-location",
                        component: <RoutesArticlesProductCleanings title={null} parents="articles/location/product" current="cleanings" />
                    },
                    {
                        path: "/documents/:productId",
                        pageTitle: "Documenten",
                        permissionLevel: 1,
                        tabGroup: "product-details-location",
                        component: <RoutesArticlesProductDocuments title={null} parents="articles/location/product" current="documents" />
                    },
                    {
                        path: "/log/:productId",
                        pageTitle: "Product historie",
                        permissionLevel: 1,
                        tabGroup: "product-details-location",
                        component: <RoutesArticlesProductLog title={null} parents="articles/location/product" current="log" />
                    }
                ]
            },
            // PRODUCTS IN DEPOT
            {
                path: "/depot",
                pageTitle: "In depot",
                permissionLevel: 1,
                topMenu:true,
                tabGroup: "product-overview",
                component: <RoutesArticlesOverview title="Hulpmiddelen in depot" parents="articles" current="depot" />
            },
            {
                path: "/depot/product",
                redirect: <Navigate to={"/depot"} />,
                children: [
                    // Product actions
                    {
                        path: "/actions/:productId",
                        pageTitle: "Acties",
                        permissionLevel: 2,
                        tabGroup: "product-details-depot",
                        component: <RoutesArticlesProductActions title={null} parents="articles/depot/product" current="actions" />
                    },
                    {
                        path: "/actions/scan/:productId",
                        pageTitle: "Qr code koppelen",
                        permissionLevel: 2,
                        component: <RoutesArticlesProductActionsScan title="Qr code koppelen" parents="articles/depot/product/actions" current="scan" />
                    },
                    {
                        path: "/actions/depotout/:productId",
                        pageTitle: "Leveren uit depot",
                        permissionLevel: 2,
                        component: <RoutesArticlesProductActionsDepotout title={null} parents="articles/depot/product/actions" current="depotout" />
                    },
                    {
                        path: "/actions/reservation/:productId",
                        pageTitle: "Reservering registreren",
                        permissionLevel: 2,
                        component: <RoutesArticlesProductActionsReservation title={null} parents="articles/depot/product/actions" current="reservation" />
                    },
                    {
                        path: "/actions/edit/:productId",
                        pageTitle: "Artikelgegevens wijzigen",
                        permissionLevel: 2,
                        component: <RoutesArticlesProductActionsEdit title={null} parents="articles/depot/product/actions" current="edit" />
                    },
                    // Product details
                    {
                        path: "/details/:productId",
                        pageTitle: "Details",
                        permissionLevel: 1,
                        tabGroup: "product-details-depot",
                        component: <RoutesArticlesProductDetails title={null} parents="articles/depot/product" current="details" />
                    },
                    {
                        path: "/repairs/:productId",
                        pageTitle: "Reparaties",
                        permissionLevel: 1,
                        tabGroup: "product-details-depot",
                        component: <RoutesArticlesProductRepairs title={null} parents="articles/depot/product" current="repairs" />
                    },
                    {
                        path: "/inspections/:productId",
                        pageTitle: "Keuringen",
                        permissionLevel: 1,
                        tabGroup: "product-details-depot",
                        component: <RoutesArticlesProductInspections title={null} parents="articles/depot/product" current="inspections" />
                    },
                    {
                        path: "/cleanings/:productId",
                        pageTitle: "Reinging",
                        permissionLevel: 1,
                        tabGroup: "product-details-depot",
                        component: <RoutesArticlesProductCleanings title={null} parents="articles/depot/product" current="cleanings" />
                    },
                    {
                        path: "/documents/:productId",
                        pageTitle: "Documenten",
                        permissionLevel: 1,
                        tabGroup: "product-details-depot",
                        component: <RoutesArticlesProductDocuments title={null} parents="articles/depot/product" current="documents" />
                    },
                    {
                        path: "/log/:productId",
                        pageTitle: "Product historie",
                        permissionLevel: 1,
                        tabGroup: "product-details-depot",
                        component: <RoutesArticlesProductLog title={null} parents="articles/depot/product" current="log" />
                    }
                ]
            },
            // DEPOT CASES
            {
                path: "/depot-pending",
                pageTitle: "Opdrachten",
                permissionLevel: 1,
                tabGroup: "product-overview",
                component: <RoutesArticlesDepotPending title="Depot opdrachten" parents="articles" current="depot-pending" />,
                children: [
                    {
                        path: "/details/:depotCaseId",
                        pageTitle: "Details",
                        permissionLevel: 1,
                        component: <RoutesArticlesDepotDetails title={null} parents="articles/depot-pending" current="details" />
                    }
                ]
            },
            // DEPOT CASES ARCHIVE
            {
                path: "/depot-archive",
                pageTitle: "Archief",
                permissionLevel: 1,
                tabGroup: "product-overview",
                component: <RoutesArticlesDepotArchive title="Archief opdrachten" parents="articles" current="depot-archive" />,
                children: [
                    {
                        path: "/details/:depotCaseId",
                        pageTitle: "Details",
                        permissionLevel: 1,
                        component: <RoutesArticlesDepotDetails title={null} parents="articles/depot-archive" current="details" />
                    }
                ]
            },
            // INSPECTIONS
            {
                path: "/inspections",
                redirect: <Navigate to={"/articles/inspections/open"} />,
                pageTitle: "Keuringen",
                permissionLevel: 1,
                topMenu:true,
                children: [
                    {
                        path: "/open",
                        pageTitle: "Te keuren",
                        permissionLevel: 1,
                        tabGroup: "inspections",
                        component: <RoutesArticlesInspectionsOverview title="Te keuren" parents="articles/inspections" current="open" />
                    },
                    {
                        path: "/archive",
                        pageTitle: "Archief",
                        permissionLevel: 1,
                        tabGroup: "inspections",
                        component: <RoutesArticlesInspectionsOverview title="Archief keuringen" parents="articles/inspections" current="archive" />
                    }
                ]
            },
            // REPAIRS
            {
                path: "/repairs",
                redirect: <Navigate to={"/articles/repairs/open"} />,
                pageTitle: "Reparaties",
                permissionLevel: 1,
                topMenu:true,
                children: [
                    {
                        path: "/open",
                        pageTitle: "Reparaties",
                        permissionLevel: 1,
                        tabGroup: "repairs",
                        component: <RoutesArticlesRepairsOverview title="Openstaande reparaties" parents="articles/repairs" current="open" />
                    },
                    {
                        path: "/archive",
                        pageTitle: "Archief",
                        permissionLevel: 1,
                        tabGroup: "repairs",
                        component: <RoutesArticlesRepairsOverview title="Archief reparaties" parents="articles/repairs" current="archive" />
                    }
                ]
            },
            // ADD PRODUCT
            {
                path: "/add",
                pageTitle: "Hulpmiddel registreren",
                permissionLevel: 2,
                component: <RoutesArticlesAdd title="Hulpmiddel registreren" parents="articles/actions" current="add" />,
            }

        ]
    },
    // SEGMENT REQUESTS
    {
        path: "/requests",
        pageTitle: "Aanvragen",
        permissionLevel: 1,
        topMenu:true,
        redirect: <Navigate to={"/requests/open"} />,
        children: [
            {
                path: "/open",
                pageTitle: "Aanvragen",
                permissionLevel: 1,
                tabGroup: "customer-requests",
                component: <RoutesRequestsOverview title="Lopende aanvragen" parents="requests" current="open" />,
                children: [
                    {
                        path: "/details/:customerRequestId",
                        pageTitle: "Details aanvraag",
                        permissionLevel: 1,
                        component: <RoutesRequestsDetails parents="requests" current="details" />
                    }
                ]
            },
            {
                path: "/archive",
                pageTitle: "Archief",
                permissionLevel: 1,
                tabGroup: "customer-requests",
                component: <RoutesRequestsOverview title="Archief aanvragen" parents="requests" current="archive" />,
                children: [
                    {
                        path: "/details/:customerRequestId",
                        pageTitle: "Details aanvraag",
                        permissionLevel: 1,
                        component: <RoutesRequestsDetails parents="requests/archive" current="details" />
                    }
                ]
            },
        ]
    },

    // ////////// SEGMENT MANAGEMENT //////////
    {
        path: "/management",
        pageTitle: "Management info",
        permissionLevel: 1,
        topMenu:true,
        redirect: <Navigate to={"/management/invoices"} />,
        children: [
            {
                path: "/invoices",
                pageTitle: "Facturen",
                permissionLevel: 1,
                tabGroup: "management",
                component: <RoutesManagementInvoices title="Facturen" parents="management" current="invoices" />
            },
            {
                path: "/overviews",
                pageTitle: "Overzichten",
                permissionLevel: 1,
                tabGroup: "management",
                component: <RoutesManagementOverviews title="Overzichten" parents="management" current="overviews" />
            },
            {
                path: "/overviews/rental",
                pageTitle: "Verhuur",
                permissionLevel: 1,
                component: <RoutesManagementOverviewsRental title="Overzicht verhuur" parents="management/overviews" current="rental" />
            },
            {
                path: "/overviews/repairs",
                pageTitle: "Reparaties",
                permissionLevel: 1,
                component: <RoutesManagementOverviewsRepairs title="Overzicht reparaties" parents="management/overviews" current="repairs" />
            },
            {
                path: "/overviews/depot",
                pageTitle: "Depotopdrachten",
                permissionLevel: 1,
                component: <RoutesManagementOverviewsDepot title="Overzicht depotopdrachten" parents="management/overviews" current="depot" />
            },
            {
                path: "/rentalcost",
                pageTitle: "Verhuurkosten",
                permissionLevel: 1,
                tabGroup: "management",
                component: <RoutesManagementRentalcost title="Verhuurkosten" parents="management" current="rentalcost" />
            },
            {
                path: "/overviews/productslocation",
                pageTitle: "Op locatie",
                permissionLevel: 1,
                component: <RoutesManagementOverviewsProducts title="Hulpmiddelen op locatie" parents="management/overviews"current="productslocation" /> 
            },
            {
                path: "/overviews/productsdepot",
                pageTitle: "In depot",
                permissionLevel: 1,
                topMenu:true,
                component: <RoutesManagementOverviewsProducts title="Hulpmiddelen in depot" parents="management/overviews" current="productsdepot" />
            },
        ]
    },

    // ####################### oude urls refactored/refactoring ###################### //
    // { path: "/login", redirect: <Navigate to={"/auth/login"} />},
    { path: "/products", redirect: <Navigate to={"/articles/location/product/actions"} /> },
    { path: "/products/actions", redirect: <Navigate to={"/articles/actions"} /> },
    { path: "/products/all", redirect: <Navigate to={"/articles/location"} /> },
    { path: "/depot", redirect: <Navigate to={"/articles/depot"} /> },
    { path: "/depot/products", redirect: <Navigate to={"/articles/depot"} /> },
    { path: "/depot/tasks", redirect: <Navigate to={"/articles/depot-pending"} /> },
    { path: "/depot/tasksarchive", redirect: <Navigate to={"/articles/depot-archive"} /> },
    { path: "/products/addproduct", redirect: <Navigate to={"/articles/add"} /> },
    { path: "/invoices", redirect: <Navigate to={"/management/invoices"} /> },
    { path: "/scan/rental", redirect: <Navigate to={"/rentals/actions/scan"} /> },
    { path: "/rentals/requests", redirect: <Navigate to={"/rentals/actions/requests"} /> },
    { path: "/scan/default", redirect: <Navigate to={"/scan"} /> },
    { path: "/scan/products", redirect: <Navigate to={"/scan"} /> },
    { path: "/scan/rental", redirect: <Navigate to={"/scan"} /> },

]
